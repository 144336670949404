import * as RiIcons from "react-icons/ri";

export const MenuData = [
  {
    title: "플레이송스란?",
    path: "/AboutUs",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
  },
  {
    title: "교육정보",
    path: "",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,

    subNav: [
      {
        title: "커리큘럼",
        path: "/Curriculum",
        cName: "sub-nav",
      },
      {
        title: "잉글리시 클럽",
        path: "/PEnglishClub",
        cName: "sub-nav",
      },
      {
        title: "시간표",
        path: "/schedule",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Admission",
    path: "",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "프로그램 신청하기",
        path: "https://forms.gle/1TywhXpGTEbj5zyy5",
        cName: "sub-nav",
      },
      {
        title: "문의하기",
        path: "/apply",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Contact Us",
    path: "",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "센터소개",
        path: "/ContactUs",
        cName: "sub-nav",
      },
      {
        title: "채용문의",
        path: "/WorkWithUs",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Products",
    path: "",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,

    subNav: [
      {
        title: "스마트스토어",
        path: "https://smartstore.naver.com/bicycle",
        cName: "sub-nav",
      },
      {
        title: "플레이송스홈",
        path: "https://www.playsongshome.com/",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Our Service",
    path: "/FamilySite",
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
  },
];

export const BicycleMenuData = [
  {
    title: "PHILOSOPHY",
    path: "Philosophy",
  },
  {
    title: "HISTORY",
    path: "History",
  },
  {
    title: "BUSINESS FIELD",
    path: "Business",
  },
  {
    title: "CONTACT",
    path: "Contact",
  },
];
