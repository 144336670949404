import { useState, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { styled } from "styled-components";
import { HistoryCard, MHistoryCard } from "../../Componets/HistoryCard";
import { FadeIn } from "../../Componets/common";
import historydata from "../../Json/HistoryCardInfo.json";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useDrag from "../../Componets/useDrag";
import usePreventBodyScroll from "../../usePreventBodyScroll";
import "../../Css/hideScrollbar.css";
import { Desktop, Tablet } from "../../Device";
import { ILanguage } from "./BHome";

const Background = styled.div`
  background-size: cover;
  width: 100%;
  height: 60.25vw; // 100%;
  background-color: #fafafa;
`;
const Title = styled.div<{ $visible: boolean; time: number; delay: number }>`
  ${(props) => FadeIn(props.$visible, props.time, props.delay)}

  width: 100%;

  @media (min-width: 1024px) {
    padding-top: 4.7656vw;
    font-size: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 5.2083vw;
    font-size: 3.125vw;
  }
  @media (max-width: 767px) {
  }
  line-height: 1.21;
  font-family: Pretendard;
  font-weight: 900;
  text-align: center;
  color: #492d1c;

  text-transform: uppercase;
`;

const Description = styled.div<{
  visible: boolean;
  time: number;
  delay: number;
  iskor?: boolean;
}>`
  ${(props) => FadeIn(props.visible, props.time, props.delay)}

  width: 100%;
  @media (min-width: 1024px) {
    margin: 1.4844vw;
    font-size: 1.5625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: ${({ iskor }) => (iskor ? "2.6042vw" : "2.3438vw")};
  }
  @media (max-width: 767px) {
  }
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: #222;
`;
const ArrowSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: 1024px) {
    width: 6.7188vw;
    margin-left: 44.9219vw;
    margin-top: 2.7734vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 11.1979vw;
    margin-left: auto; //33.9844vw;
    margin-right: auto;
    margin-top: 1.5625vw;
    //background-color: blue;
  }
`;

const Arrow = styled.div`
  @media (min-width: 1024px) {
    width: 2.7813vw;
    height: 0.9844vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 4.6354vw;
    height: 1.6406vw;
  }
  position: relative;
  display: flex;
  align-items: center;
`;

const ArrowPart = styled.div`
  width: 0;
  @media (min-width: 1024px) {
    height: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    height: 3.125vw;
  }
  border: solid 0.1302vw #757575;
`;

const BearSection = styled.div`
  clear: both;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  width: 100%;
  border-bottom: solid 1px #492d1c;

  @media (min-width: 1024px) {
    margin-top: 2.1875vw;
    height: 4.5313vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 4.8177vw;
    height: 7.5vw;
  }
  @media (max-width: 767px) {
  }
`;

const BearImg = styled.div<{ width: number; height: number }>`
  width: ${(props) => props.width}vw;
  height: ${(props) => props.height}vw;
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const HistroySection = styled.div`
  clear: both;
  @media (min-width: 1024px) {
    margin: 1.2891vw 7.5781vw 0 8.125vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin: 2.5391vw 0 0 2.6042vw;
  }
`;
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

export default function BHistory({ isKor }: ILanguage) {
  const apiRef = useRef({} as scrollVisibilityApiType);

  function RightArrow() {
    apiRef.current.scrollToItem(apiRef.current.getNextItem(), "smooth", "end");
  }

  function LeftArrow() {
    apiRef.current.scrollToItem(
      apiRef.current.getPrevItem(),
      "smooth",
      "start"
    );
  }

  const [desc, setDesc] = useState(false);
  const delay = 1000;

  const [ref, inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        const timer = setTimeout(() => {
          setDesc(true);
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setDesc(false);
      }
    },
  });

  return (
    <Background ref={ref}>
      <Title $visible={inView} time={0} delay={0}>
        company history
      </Title>
      <Description
        className="text_wrap"
        visible={desc}
        time={0}
        delay={0}
        iskor={isKor}
      >
        <Desktop>
          {isKor
            ? "바이시클은 모든 아이들이 적기에 필요한 이중언어와 음악을 중점으로 자연스러운 통합교육을 실현하고자 시작되었습니다.\n바이시클은 2007년부터 구축한 DB와 노하우, 브랜드는 디지털과정을 통해 온라인교육을\n현실화함으로 성장중이며, DB기반의 AI플랫폼과 스마트 교구재 회사로\사업을 확장하고 있습니다.\n"
            : "Bicycle, founded in 2007, aims to provide holistic early childhood education emphasizing dual-language and music skills. \nLeveraging its established database, expertise, and brand, Bicycle has transitioned to \nonline education, incorporating AI platforms and smart educational materials for further expansion."}
        </Desktop>
        <Tablet>
          {isKor
            ? "바이시클은 모든 아이들이 적기에 필요한 이중언어와 음악을 중점으로\n자연스러운 통합교육을 실현하고자 시작되었습니다.\n바이시클은 2007년부터 구축한 DB와 노하우,\n브랜드는 디지털과정을 통해 온라인교육을 현실화함으로 성장중이며,\nDB기반의 AI플랫폼과 스마트 교구재 회사로 사업을 확장하고 있습니다.\n"
            : "Bicycle, founded in 2007, aims to provide holistic early childhood \neducation emphasizing dual-language and music skills. Leveraging \nits established database, expertise, and brand, Bicycle has \ntransitioned to online education, incorporating AI platforms and \nsmart educational materials for further expansion."}
        </Tablet>
      </Description>
      <ArrowSection>
        <Arrow onClick={LeftArrow}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/arrow.png"
            alt="arrow"
            width={"100%"}
          />
        </Arrow>
        <ArrowPart />
        <Arrow className="flip" onClick={RightArrow}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/arrow.png"
            alt="arrow"
            width={"100%"}
          />
        </Arrow>
      </ArrowSection>
      <BearImageSection />
      <HistorySectionView scrollRef={apiRef} isKor={isKor} />
    </Background>
  );
}

const MTitle = styled.div<{ $visible: boolean; time: number; delay: number }>`
  ${(props) => FadeIn(props.$visible, props.time, props.delay)}

  width: 100%;
  padding-top: 4.8718vw;
  font-size: 5.641vw;
  font-weight: 900;
  line-height: 1.18;
  text-align: center;
  color: #492d1c;
  text-transform: uppercase;
`;

const MDescription = styled.div<{
  visible: boolean;
  time: number;
  delay: number;
}>`
  ${(props) => FadeIn(props.visible, props.time, props.delay)}

  width: 100%;
  margin: 1.4844vw;
  font-size: 4.1026vw;
  font-weight: bold;
  line-height: 1.63;
  text-align: center;
  color: #222;

  margin-top: 3.0769vw;
`;

const MArrowSection = styled(ArrowSection)`
  width: 30.7692vw;
  margin-left: 30.1282vw;
`;
const MArrow = styled(Arrow)`
  width: 9.1282vw;
  height: 3.2308vw;
`;

const MArrowPart = styled.div`
  width: 0;
  height: 6.1538vw;
  border: solid 1px #757575;
`;

const MBearSection = styled(BearSection)`
  margin-top: 4.8718vw;
  height: 15.0256vw;
  margin-bottom: 4.7436vw;
`;

const MHistroySection = styled(HistroySection)`
  margin-left: 0.1282vw;
  margin-right: 0.1282vw;
`;

export function BHistoryM({ isKor }: ILanguage) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
      (ev: React.MouseEvent) =>
        dragMove(ev, (posDiff) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
        });

  const [selected, setSelected] = useState<string>("");
  const handleItemClick = (itemId: string) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };

  const apiRef = useRef({} as scrollVisibilityApiType);

  function RightArrow() {
    apiRef.current.scrollToItem(apiRef.current.getNextItem(), "smooth", "end");
  }

  function LeftArrow() {
    apiRef.current.scrollToItem(
      apiRef.current.getPrevItem(),
      "smooth",
      "start"
    );
  }

  const [desc, setDesc] = useState(false);
  const delay = 1000;

  const [ref, inView] = useInView({
    threshold: 0.2,
    onChange: (inView) => {
      if (inView) {
        const timer = setTimeout(() => {
          setDesc(true);
        }, delay);
        return () => clearTimeout(timer);
      } else {
        setDesc(false);
      }
    },
  });

  return (
    <Background ref={ref}>
      <MTitle $visible={inView} time={1} delay={0}>
        company history
      </MTitle>
      <MDescription className="text_wrap" visible={desc} time={1} delay={0}>
        {isKor
          ? "바이시클은 모든 아이들이 적기에 필요한\n이중언어와 음악을 중점으로\n자연스러운 통합교육을\n실현하고자 시작되었습니다.\n바이시클은 2007년부터 구축한 DB와 노하우,\n브랜드는 디지털과정을 통해\n온라인교육을 현실화함으로 성장중이며,\nDB기반의 AI플랫폼과\n스마트 교구재 회사로 사업을 확장하고 있습니다."
          : "Bicycle, founded in 2007, aims to \nprovide holistic early childhood\n education emphasizing dual-language \nand music skills. Leveraging its\n established database, expertise, and \nbrand, Bicycle has transitioned to online \neducation, incorporating AI platforms \nand smart educational materials for \nfurther expansion."}
      </MDescription>
      <MArrowSection>
        <MArrow onClick={LeftArrow}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/arrow.png"
            alt="arrow"
            width={"100%"}
          />
        </MArrow>
        <MArrowPart />
        <MArrow className="flip" onClick={RightArrow}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/arrow.png"
            alt="arrow"
            width={"100%"}
          />
        </MArrow>
      </MArrowSection>
      <MBearSection>
        <BearImg width={12.0513} height={14.4103}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/bear-1.png"
            alt="bear-1"
            width={"100%"}
          />
        </BearImg>
        <BearImg width={16.6923} height={14.7692}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/bear-2.png"
            alt="bear-2"
            width={"100%"}
          />
        </BearImg>
        <BearImg width={13.4872} height={11.5385}>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/bear-3.png"
            alt="bear-3"
            width={"100%"}
          />
        </BearImg>
      </MBearSection>

      <MHistroySection onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
        <ScrollMenu apiRef={apiRef}>
          {historydata.data.map(
            (
              item: {
                year: string;
                yearE: string;
                Desc: string;
                DescE: string;
                img: string;
              },
              index: any
            ) => {
              return (
                <MHistoryCard
                  key={index}
                  year={isKor ? item.year : item.yearE}
                  descript={isKor ? item.Desc : item.DescE}
                  img={item.img}
                  onClick={handleItemClick(item.year)}
                  selected={item.year === selected}
                />
              );
            }
          )}
        </ScrollMenu>
      </MHistroySection>
    </Background>
  );
}

interface IBearImgProps {
  imgSrc: string;
  altSrc: string;
  width: number;
  height: number;
}
function BearImageView({ imgSrc, altSrc, width, height }: IBearImgProps) {
  return (
    <BearImg width={width} height={height}>
      <img src={imgSrc} alt={altSrc} width={"100%"} />
    </BearImg>
  );
}

function BearImageSection() {
  return (
    <BearSection>
      <Desktop>
        <BearImageView
          imgSrc={"https://playsongshome.playsongs.ai/bicycle/bear-1.png"}
          altSrc={"bear-1"}
          width={3.6719}
          height={4.3906}
        />

        <BearImageView
          width={5.0859}
          height={4.5}
          imgSrc="https://playsongshome.playsongs.ai/bicycle/bear-2.png"
          altSrc="bear-2"
        />
        <BearImageView
          width={4.1094}
          height={3.5156}
          imgSrc="https://playsongshome.playsongs.ai/bicycle/bear-3.png"
          altSrc="bear-3"
        />
      </Desktop>
      <Tablet>
        <BearImageView
          imgSrc={"https://playsongshome.playsongs.ai/bicycle/bear-1.png"}
          altSrc={"bear-1"}
          width={6.1198}
          height={7.2917}
        />

        <BearImageView
          width={8.4766}
          height={7.5}
          imgSrc="https://playsongshome.playsongs.ai/bicycle/bear-2.png"
          altSrc="bear-2"
        />
        <BearImageView
          width={6.849}
          height={5.8594}
          imgSrc="https://playsongshome.playsongs.ai/bicycle/bear-3.png"
          altSrc="bear-3"
        />
      </Tablet>
    </BearSection>
  );
}

// ILanguage와 IDevice를 결합하여 새로운 타입 정의
type HistorySectionViewProps = ILanguage & any;
function HistorySectionView({ scrollRef, isKor }: HistorySectionViewProps) {
  const { disableScroll, enableScroll } = usePreventBodyScroll();
  const { dragStart, dragStop, dragMove, dragging } = useDrag();
  const handleDrag =
    ({ scrollContainer }: scrollVisibilityApiType) =>
      (ev: React.MouseEvent) =>
        dragMove(ev, (posDiff) => {
          if (scrollContainer.current) {
            scrollContainer.current.scrollLeft += posDiff;
          }
        });

  const [selected, setSelected] = useState<string>("");
  const handleItemClick = (itemId: string) => () => {
    if (dragging) {
      return false;
    }
    setSelected(selected !== itemId ? itemId : "");
  };
  return (
    <HistroySection onMouseEnter={disableScroll} onMouseLeave={enableScroll}>
      <ScrollMenu apiRef={scrollRef}>
        {historydata.data.map(
          (
            item: {
              year: string;
              yearE: string;
              Desc: string;
              DescE: string;
              img: string;
            },
            index: any
          ) => {
            return (
              <HistoryCard
                key={index}
                year={isKor ? item.year : item.yearE}
                descript={isKor ? item.Desc : item.DescE}
                img={item.img}
                onClick={handleItemClick(item.year)}
                selected={item.year === selected}
              />
            );
          }
        )}
      </ScrollMenu>
    </HistroySection>
  );
}
