import { styled } from "styled-components";
import { ILanguage } from "./BHome";


export default function BPhilsophy2({ isKor }: ILanguage) {
    return (<>
        {isKor ?
            <BPhilsophyKor2 /> :
            <BPhilsophyEng2 />
        }
    </>
    )
}
function BPhilsophyKor2() {
    return <>
        <HeaderSection
            title="Corporate Mission"
            description="모든 아이들이 시간적, 공간적 제약없이 누릴 수 있는 음악중심 놀이 경험을 행복한 아이들을 만듭니다."
            isFirst={true} />
        <HeaderSection
            title="Business Mission"
            description="현장에서 만들어진 검증된 코어 IP 중심의 스마트 콘텐츠 및 교구재개발/ 유통/ 서비스의 선순환 사이클 플랫폼 구축" />
        <HeaderSection
            title="Business Sector"
            description='#Offline   #Online   #SmartToys   #Sound Book base game App   #Academy  Homeschool  & Books  #AI Base Play & Platform' />
    </>
}

function BPhilsophyEng2() {
    return <>
        <HeaderSection
            title="Corporate Mission"
            description="We create joyful children through music-centered play experiences that are accessible anytime, anywhere."
            isFirst={true}
        />
        <HeaderSection
            title="Business Mission"
            description="Building a platform that enables a virtuous cycle of development, distribution, and service of smart content and educational tools based on proven core IP from the field."
        />
        <HeaderSection
            title="Business Sector"
            description='#Offline   #Online   #SmartToys   #Sound Book base game App   #Academy  Homeschool  & Books  #AI Base Play & Platform' /></>
}

interface HeaderSectionProps {
    title: string;
    description: string;
    isFirst?: boolean;
}


const Wrapper = styled.div<{ $isFirst?: boolean }>`
@media (min-width: 1023px) {
  display: flex;
  align-items: flex-start;  
}
   margin-top: ${({ $isFirst }) => ($isFirst ? '7.8125vw' : '6.25vw')};
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  
width: 22.5391vw;
  white-space: nowrap;
`;

const Bar = styled.div`
 font-family: Pretendard;
 
  font-weight: 900;
  line-height: 1.21;
   @media (min-width: 1023px) {
    font-size: 1.8750vw;
   }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
  }
  @media (max-width: 767px)
  {
    font-size: 5.641vw;
  }
`;

const TitleText = styled(Bar)`
margin-left: 1.5234vw;
`;

const Description = styled.p < { iskor?: boolean; } >`
 font-family: Pretendard;  
  text-align: left;
  line-height: 1.6;
   color: #222;
    @media (min-width: 1023px) {
    width: 61.6406vw;
    font-size: 1.5625vw;
    }
   @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.5625vw;
    font-size: ${({ iskor }) => (iskor ? "2.6042vw" : "2.3438vw")};
  }
  @media (max-width: 767px) {

    font-size: 4.1026vw;
}
   
`;


const HeaderSection: React.FC<HeaderSectionProps> = ({ title, description, isFirst }, { isKor }: ILanguage) => {
    return (
        <Wrapper $isFirst={isFirst}>
            <TitleWrapper>
                <Bar >|</Bar>
                <TitleText>{title}</TitleText>
            </TitleWrapper>
            <Description className="text_wrap" >{description}</Description>
        </Wrapper>
    );
};