import { styled } from "styled-components";
import { Desktop, Tablet } from "../../Device";
import { ILanguage } from "./BHome";

const TitleView = styled.div`
  clear: both;

  font-weight: 900;
  line-height: 1.21;
  text-align: center;
  color: #492d1c;
  text-transform: uppercase;

  @media (min-width: 1024px) {
    margin-top: 7.8125vw;
    font-size: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.6458vw;
    font-size: 3.125vw;
  }
  @media (max-width: 767px) {
  }
`;
const ImageView = styled.div`
  //    width: 96.5625vw;

  object-fit: contain;

  @media (min-width: 1024px) {
    height: 46.25vw;
    margin-top: 3.9063vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 3.5156vw;
    height: 50vw;

    //background-color: blue;
  }
  @media (max-width: 767px) {
    width: 100%;
  }
`;
export default function BBusiness({ isKor }: ILanguage) {
  return (
    <>
      <TitleView>our brand</TitleView>
      <ImageView>
        <Desktop>
          <img
            src={isKor ?
              "https://playsongshome.playsongs.ai/bicycle/ourbrand/pc-ourbrand%403x.png" :
              "https://playsongshome.playsongs.ai/bicycle/pc-ourbrand-en%403x.png"}
            alt="ourbrand"
            width={"100%"}
          />
        </Desktop>
        <Tablet>
          <img
            src={isKor ?
              "https://playsongshome.playsongs.ai/bicycle/ourbrand/ta-ourbrand%403x.png" :
              "https://playsongshome.playsongs.ai/bicycle/tablet/ta-ourbrand-en%403x.png"}
            alt="ourbrand"
            width={"100%"}
          />
        </Tablet>
      </ImageView>
    </>
  );
}

const TitleView2 = styled.div`
  clear: both;
  margin-top: 5.1282vw;
  font-size: 5.641vw;
  font-weight: 900;
  line-height: 1.18;
  text-align: center;
  color: #492d1c;
  text-transform: uppercase;
`;
const ImageView2 = styled.div`
  margin-top: 5.1282vw;
  width: 94.8718vw;
  //height: 61.5385vw;
  object-fit: contain;
`;

export function BBusinessM({ isKor }: ILanguage) {
  return (
    <>
      <TitleView2>our brand</TitleView2>
      <ImageView2>
        <img
          src={isKor ?
            "https://playsongshome.playsongs.ai/bicycle/ourbrand/mo-ourbrand@3x.png" :
            "https://playsongshome.playsongs.ai/bicycle/mo-brand-en%403x.png"
          }
          alt=""
          width={"100%"}
        />
      </ImageView2>
    </>
  );
}
