export const apScheduleTimeData = [
  {
    time: [
      [
        {
          title: "10:30",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "11:30",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "13:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "14:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "15:00",
          color: "#222222",
          bold: true,
        },
      ],
      [
        {
          title: "16:00",
          color: "#222222",
          bold: true,
        },
      ],
    ],
  },
];
export const apScheduleData = [
  {
    title: "화",
    time: [
      [
        {
          title: "플레이찰리",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "(10:00~12:00)",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
      ],
      [
        {
          title: "플레이찰리",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "(10:00~12:00)",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "1 천재음악가",
          color: "#439c2b",

          bold: false,
        },
      ],
      [
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
      ],
      [],
      [
        {
          title: "1 천재음악가 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
      ],

      [],
    ],
  },
  {
    title: "수",
    time: [
      [
        {
          title: "5 음악극1",
          color: "#f39800",
          bold: false,
        },
        {
          title: "3 오늘도 즐거워 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],
      [
        {
          title: "1 천재음악가 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],
      [
        {
          title: "1 천재 음악가",
          color: "#439c2b",
          bold: false,
        },
      ],
      [],
      [
        {
          title: "4 테마극장 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],

      [],
    ],
  },
  {
    title: "목",
    time: [
      [
        {
          title: "플레이찰리",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "(10:00~12:00)",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "1 천재 음악가",
          color: "#439c2b",
          bold: false,
        },
      ],
      [
        {
          title: "플레이찰리",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "(10:00~12:00)",
          color: "#876FA3",
          bold: false,
        },
        {
          title: "3 놀기대장",
          color: "#cf0047",
          bold: false,
        },
      ],
      [],
      [
        {
          title: "1 천재 음악가 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],
      [
        {
          title: "1 천재 음악가 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],
      [
        {
          title: "4 신나는 모험",
          color: "#ea5520",
          bold: false,
        },

        {
          title: "3 오늘도 즐거워 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
      ],
    ],
  },
  {
    title: "금",
    time: [
      [
        {
          title: "4 신나는 모험",
          color: "#ea5520",
          bold: false,
        },
      ],
      [
        {
          title: "3 놀기대장",
          color: "#cf0047",
          bold: false,
        },
      ],
      [],
      [
        {
          title: "1 천재음악가",
          color: "#439c2b",
          bold: false,
        },
        {
          title: "3 놀기대장",
          color: "#cf0047",
          bold: false,
        },
      ],
      [
        {
          title: "1 천재음악가",
          color: "#439c2b",
          bold: false,
        },
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
      ],

      [],
    ],
  },
  {
    title: "토",
    time: [
      [
        {
          title: "5 음악극3 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
        {
          title: "5 음악극1",
          color: "#f39800",
          bold: false,
        },
      ],
      [
        {
          title: "3 놀기대장",
          color: "#cf0047",
          bold: false,
        },

        {
          title: "1 천재음악가",
          color: "#439c2b",
          bold: false,
        },
      ],
      [
        {
          title: "5 음악극4 (마감)",
          color: "#9e9e9e",
          bold: false,
        },
        {
          title: "4 테마극장",
          color: "#ea5520",
          bold: false,
        },
      ],

      [
        {
          title: "2 나도나도",
          color: "#007bc7",
          bold: false,
        },
        {
          title: "3 놀기대장",
          color: "#cf0047",
          bold: false,
        },
      ],

      [],
      [],
    ],
  },
];
