import styled from "styled-components";
import { handleOpenNewTab } from "../../Componets/common";
import { IDevice, ILanguage } from "./BHome";

const ContactSection = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
  }
  @media (max-width: 767px) {
  }

  clear: both;
  height: 100%;
  width: 100%;
  background-color: #e0e0e0;
`;
const ContactUsSection = styled.div`
  @media (min-width: 1024px) {
    margin-left: 8.125vw;
    width: 38.5938vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 4.1667vw;
    margin-left: 3.125vw;
  }
  @media (max-width: 767px) {
    margin-left: 2.3077vw;
    padding-top: 2.3077vw;
  }
`;

const ContactusTitle = styled.div`
  @media (min-width: 1024px) {
    font-size: 1.875vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: 3.125vw;
  }
  font-weight: 900;
  line-height: 1.21;
  text-align: left;
  color: #492d1c;
  text-transform: uppercase;
`;
const ContactusDescript = styled.div`
  @media (min-width: 1024px) {
    width: 38.5938vw;
    margin: 0.9375vw 0 0;
    font-size: 1.4063vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
    font-size: 2.3438vw;
  }
  @media (max-width: 767px) {
  }
  font-weight: bold;
  line-height: 1.44;
  text-align: left;
  color: #222;
`;

const ContactusButtonSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (min-width: 1024px) {
    margin-top: 1.0156vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    margin-top: 1.0417vw;
  }
  @media (max-width: 767px) {
  }
  width: 100%;
`;

const ContactusButton = styled.button`
  @media (min-width: 1024px) {
    width: 9.375vw;
    height: 3.125vw;
    border-radius: 0.3125vw;
    margin-right: 0.625vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 15.625vw;
    height: 5.2083vw;
    border-radius: 0.5208vw;
    margin-right: 1.5625vw;
  }
  @media (max-width: 767px) {
  }

  span {
    @media (min-width: 1024px) {
      font-size: 1.4063vw;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      font-size: 2.3438vw;
    }
    @media (max-width: 767px) {
      font-size: 4.1026vw;
    }
    font-family: Pretendard;
    font-weight: bold;
    text-align: center;
  }
  background-color: #492d1c;
  border: none;
`;

const ContactusButtonE1 = styled(ContactusButton) <{ isTablet?: boolean }>`
@media (min-width: 1024px) {
  width : 15.6250vw;
}
@media (min-width: 768px) and (max-width: 1023px) {
  width : ${({ isTablet }) => (isTablet ? "14.3229vw" : "15.625vw")};
}

span {
  @media (min-width: 1024px) {
    font-size: 1.4063vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ isTablet }) => (isTablet ? " 2.0833vw" : "2.3438vw")};
  }

}
`

const ContactusButtonE2 = styled(ContactusButton) <{ isTablet?: boolean }>`
@media (min-width: 1024px) {
  width : 15.6250vw;
}
@media (min-width: 768px) and (max-width: 1023px) {
  width : ${({ isTablet }) => (isTablet ? "22.1354vw" : "15.625vw")};
}
span {
  @media (min-width: 1024px) {
    font-size: 1.4063vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    font-size: ${({ isTablet }) => (isTablet ? "2.0833vw" : "2.3438vw")};
  }
}
`

const ContactImage = styled.div<{ iskor?: boolean }>`
  @media (min-width: 1024px) {
    width: 52.8125vw;
    height: 23.5781vw;
    margin-top: 4.4375vw;
    margin-right: 1.0156vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    width: 52.3438vw;
    height: 23.3464vw;
    margin-top: ${({ iskor }) => (iskor ? "2.9297vw" : "-0.9766vw")};
    margin-left: 23.6979vw;
  }
  @media (max-width: 767px) {
  }
  object-fit: contain;
`;
// ILanguage와 IDevice를 결합하여 새로운 타입 정의
type BContactProps = ILanguage & IDevice;

export default function BContact({ isKor, isTablet }: BContactProps) {
  return (
    <>
      <ContactSection>
        <ContactUsSection>
          <ContactusTitle>contact us</ContactusTitle>
          <ContactusDescript className="text_wrap">
            {isKor ?
              "바이시클에 문의사항이 있으시면 온라인 문의를 이용해보세요.\n담당자가 빠르게 검토하여 연락드리겠습니다." :
              isTablet ?
                "For more information about bicycles, please use \nthe online inquiry. The person in charge will review \nit promptly and get in touch with you." :
                "For more information about bicycles, please use the online \ninquiry. The person in charge will review it promptly \nand get in touch with you."}
          </ContactusDescript>
          <ContactusButtonSection>
            {isKor ?
              (<>
                <ContactusButton
                  className="contactus_button"
                  onClick={() =>
                    handleOpenNewTab("https://forms.gle/S6mHFxBmtQFGCwUB9")
                  }
                >
                  <span>제휴문의</span>
                </ContactusButton>
                <ContactusButton
                  className="contactus_button"
                  onClick={() =>
                    handleOpenNewTab(
                      "https://www.jobkorea.co.kr/Recruit/Co_Read/C/19163043"
                    )
                  }
                >
                  <span>크루모집</span>
                </ContactusButton>
              </>) :
              (<>
                <ContactusButtonE1 isTablet={isTablet}
                  className="contactus_button"
                  onClick={() =>
                    handleOpenNewTab("https://forms.gle/S6mHFxBmtQFGCwUB9")
                  }
                >
                  <span>Partnership</span>
                </ContactusButtonE1>
                <ContactusButtonE2 isTablet={isTablet}
                  className="contactus_button"
                  onClick={() =>
                    handleOpenNewTab(
                      "https://www.jobkorea.co.kr/Recruit/Co_Read/C/19163043"
                    )
                  }
                >
                  <span>Career Opportunity</span>
                </ContactusButtonE2>
              </>)
            }
          </ContactusButtonSection>
        </ContactUsSection>
        <ContactImage>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/contact.png"
            alt=""
            width={"100%"}
          />
        </ContactImage>
      </ContactSection>
    </>
  );
}
const MContactSection = styled.div`
  clear: both;
  margin-top: 5.1282vw;
  height: 97.4359vw;
  background-color: #e0e0e0;
`;

const MContactUsSection = styled.div`
  @media (min-width: 1024px) {
    margin-left: 2.3077vw;
    padding-top: 2.3077vw;
  }
  @media (min-width: 768px) and (max-width: 1023px) {
    padding-top: 1.3021vw;
  }
  @media (max-width: 767px) {
    margin-left: 2.3077vw;
    padding-top: 2.3077vw;
  }
`;

const MContactusTitle = styled.div`
  width: 100%;
  font-size: 5.1282vw;
  font-weight: 900;
  line-height: 1.2;
  text-align: left;
  color: #492d1c;
  text-transform: uppercase;
`;
const MContactusDescript = styled.div`
  margin-top: 1.0256vw;
  width: 100%;
  font-size: 4.1026vw;
  line-height: 1.5;
  text-align: left;
  color: #222;
`;
const MContactusButton = styled(ContactusButton)`
  width: 23.0769vw;
  height: 10.2564vw;
  border-radius: 1.0256vw;

  margin-right: 2.0513vw;
  margin-top: 3.0769vw;
`;

const MContactusButtonE1 = styled(MContactusButton)`
  width: 27.6923vw;
`;

const MContactusButtonE2 = styled(MContactusButton)`
  width: 42.8205vw;
`;
const MContactImage = styled.div`
  //margin-top: 16.9231vw;
  width: 91.7949vw;
  height: 40.9487vw;
  margin: 10.3333vw 0 0;
  padding: 0.0513vw 0 0;
`;

export function BContactM({ isKor }: ILanguage) {
  return (
    <>
      <MContactSection>
        <MContactUsSection>
          <MContactusTitle>contact us</MContactusTitle>
          <MContactusDescript className="text_wrap">
            {isKor ?
              "바이시클에 문의사항이 있으시면\n온라인 문의를 이용해보세요.\n담당자가 빠르게 검토하여 연락드리겠습니다.\n" :
              "For more information about bicycles, \nplease use the online inquiry. The person \nin charge will review it promptly and get \nin touch with you."}
          </MContactusDescript>
          <ContactusButtonSection>
            {isKor ?
              <>
                <MContactusButton
                  className="mcontactus_button"
                  onClick={() =>
                    handleOpenNewTab("https://forms.gle/S6mHFxBmtQFGCwUB9")
                  }
                >
                  <span>제휴문의</span>
                </MContactusButton>
                <MContactusButton
                  className="mcontactus_button"
                  onClick={() =>
                    handleOpenNewTab(
                      "https://www.jobkorea.co.kr/Recruit/Co_Read/C/19163043"
                    )
                  }
                >
                  <span>크루모집</span>
                </MContactusButton>
              </> :
              <>
                <MContactusButtonE1
                  className="mcontactus_button"
                  onClick={() =>
                    handleOpenNewTab("https://forms.gle/S6mHFxBmtQFGCwUB9")
                  }
                >
                  <span>Partnership</span>
                </MContactusButtonE1>
                <MContactusButtonE2
                  className="mcontactus_button"
                  onClick={() =>
                    handleOpenNewTab(
                      "https://www.jobkorea.co.kr/Recruit/Co_Read/C/19163043"
                    )
                  }
                >
                  <span>Career Opportunity</span>
                </MContactusButtonE2>
              </>}
          </ContactusButtonSection>
        </MContactUsSection>
        <MContactImage>
          <img
            src="https://playsongshome.playsongs.ai/bicycle/contact.png"
            alt=""
            width={"100%"}
          />
        </MContactImage>
      </MContactSection>
    </>
  );
}
